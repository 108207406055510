

















































































































































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import eventHub from "@/event-hub";

import {
    a11yFixBuefyModalAriaAttrs,
    a11yFixBuefyNotificationAriaAttrs
} from "@/services/a11y";

import MeetingModal from "@/components/networking/MettingModal.vue";

import Spinners from "@/components/utilities/Spinners.vue";
import NotFound from "@/views/NotFound.vue";

import { MeetingTable, SessionDataObject } from "@/types/interfaces";

//vuex Module imports
import NetworkingModule from "@/store/vuex-modules/networking";
const networkingStore = getModule(NetworkingModule);

import sessionVuexModule from "@/store/vuex-modules/getSessionData";
const sessionStore = getModule(sessionVuexModule);


export default Vue.extend ({
    components: {
        NetworkingRoomLounge: () =>
            import(
                /* webpackChunkName: "Networking" */ "@/components/networking/svg/NetworkingRoomLounge.vue"
            ),
        CoffeeShop: () =>
            import(
                /* webpackChunkName: "Networking" */ "@/components/networking/svg/CoffeeShop.vue"
            ),
        HelpDesk: () =>
            import(
                /* webpackChunkName: "Networking" */ "@/components/networking/svg/HelpDesk.vue"
            ),
        Meeting: () =>
            import(
                /* webpackChunkName: "Networking" */ "@/components/networking/svg/MeetingPark.vue"
            ),
        MeetingModal,
        Spinners,
        NotFound
    },
    directives: {
        addSvg: {
            inserted() {
                eventHub.$emit("NetworkingRoomSvgInserted");
            }
        },
        addElementWithActiveClassToggle: {
            inserted(el) {
                eventHub.$emit(
                    "NetworkingRoomElementWithActiveClassToggleInserted",
                    el
                );
            }
        },
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        },
        addbuefynotification: {
            bind: function(el) {
                eventHub.$emit("BuefyNotificationBound", el);
            }
        }
    },
    props: {
        slug: {
            type: String,
            default: "",
            required: true
        },
        id: {
            type: String,
            default: "",
            required: true
        }
    },
    // @Prop({
    //     type: String,
    //     default: "",
    //     required: true
    // })
    // slug!: string;

    // @Prop({
    //     type: String,
    //     default: "",
    //     required: true
    // })
    // id!: string;
    data() {
        return {
            componentId : "",
            isLoading : true,
            isModalOpen : false,
            meetingTables: [] as Array<MeetingTable>,
            hasRoomsData : false,
            speakeasyData:  {} as Partial<MeetingTable>,
            networkingRoomData:  null as SessionDataObject | null,
            tablesInterval : -1,
            audioPaused : true,
            pageVisitLogged : false,
        }
    },
    computed: {

        loungeComponent(): string {
            switch (this.slug) {
                case "coffee":
                    return "CoffeeShop";
                case "helpdesk":
                    return "HelpDesk";
                case "meeting":
                    return "Meeting";
                default:
                    return "NetworkingRoomLounge";
            }
        },
    
        userInfo(): Record<string, string> {
            return this.$store.getters.userInfo;
        },
    
        pageOptions(): Record<string, Record<string,string>> {
            return this.$store.getters.getPageOptions("networking") || {};
        },
    
        dataLoaded(): boolean {
            return Boolean(this.networkingRoomData) && Boolean(this.hasRoomsData);
        },
    
        legendLogo(): string {
            let returnValue = "";
            // const filename = this.pageOptions.legendLogo;
            const filename = this.loungeComponent;
    
            if (this.networkingRoomData && this.networkingRoomData.image) {
                return this.networkingRoomData.image;
            } else if (filename) {
                returnValue = "";
                // returnValue = `/legend-logos/${filename}.svg`;
            }
    
            return returnValue;
        },
    
        audioFile(): string {
            let returnValue = "";
            const filename = this.pageOptions.audioFile;
    
            if (filename) {
                returnValue = `/audio/${filename}`;
            }
    
            return returnValue;
        },
    
        helpText(): string {
            const returnValue =
                this.pageOptions.helpText[`${this.slug}`] ||
                this.pageOptions.helpText.default;
            return returnValue;
        },
    
        helpDeskText(): string {
            return this.networkingRoomData?.helpDeskText
                ? this.networkingRoomData.helpDeskText
                : "Hi! Welcome to the Help Desk. How can we help you today?";
        }
    },

    methods: {

        getLetter(sessionId: string) {
            let returnValue = "";
    
            if (sessionId) {
                const sessionIdParts = sessionId.split("-");
                const tableNumber = parseInt(
                    sessionIdParts[sessionIdParts.length - 1]
                );
                const alphabet = Vue.prototype.MgGetAlphabet();
                returnValue = alphabet[tableNumber - 1];
            }
    
            return returnValue;
        },
    
        handleTablesInterval() {
            // first cancel existing interval
            window.clearInterval(this.tablesInterval);
    
            // run on interval
            this.tablesInterval = window.setInterval(this.fetchMeetingtables, 5000);
        },
    
        fetchMeetingtables() {
            return new Promise((resolve, reject) => {
                const id = this.id;
                networkingStore
                    .getTables(id)
                    .then((x: unknown) => {
                        const response = x as Array<MeetingTable>;
                        if (response.length) {
                            this.hasRoomsData = true;
                            this.meetingTables = response;
                        }
                        return resolve(response);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
        },
    
        updateAttendeeBadgeNumber() {
            const tables = this.meetingTables;
    
            tables.map((item: MeetingTable) => {
                const id = item.sessionId.split("-").join("");
                const eL = document.getElementById(id);
                if (eL) {
                    const tspan = eL.querySelector("tspan");
                    if (tspan) {
                        tspan.innerHTML = `${item.numAttendees}`;
                    }
                }
            });
        },
    
        handleRoomItemsInit() {
            const rooms = this.meetingTables;
    
            rooms.forEach((item: MeetingTable) => {
                const eL = document.querySelector(`#${item.sessionId}`);
    
                if (eL) {
                    eL.classList.add("cursor-pointer");
    
                    eL.addEventListener("touch", () => {
                        this.openRoomModal(item);
                    });
                    eL.addEventListener("click", () => {
                        this.openRoomModal(item);
                    });
                }
            });
        },
    
        openRoomModal(data: MeetingTable) {
            const match = this.meetingTables.find((item) => {
                return item.sessionId === data.sessionId;
            });
    
            if (match) {
                this.speakeasyData = match;
                this.isModalOpen = true;
            } else {
                Vue.prototype.$rollbar.debug(
                    `Could not open networking room modal: ${JSON.stringify(data)}`
                );
            }
        },
    
        closeModal() {
            this.isModalOpen = false;
        },
    
        toggleMusic(event: Event) {
            const el = document.querySelector(`#audio-element-${this.componentId}`);
            const tar= event.target as HTMLButtonElement;
            const wrapEl = tar.closest(".button-wrap");
    
            if (wrapEl instanceof HTMLElement) {
                wrapEl.classList.add("active");
            }
    
            if (el instanceof HTMLAudioElement) {
                if (el.paused) {
                    el.play();
                } else {
                    el.pause();
                }
                this.audioPaused = el.paused;
            } else {
                console.error("No audio element.");
            }
        },
    
        handleAnimationElement(animated: HTMLElement) {
            animated.addEventListener("animationend", () => {
                animated.classList.remove("active");
            });
        },
    
        logPageVisit() {
            const networkingId = this.id;
    
            if (this.pageVisitLogged || !this.hasRoomsData) {
                return;
            }
    
            const logData = {
                type: `NetworkingLanding`,
                networkingId
            };
    
            this.$store
                .dispatch("appendLogEntry", logData)
                .then(() => {
                    this.pageVisitLogged = true;
                })
                .catch((err) => console.log(err));
        },
    
        handleBuefyModalFixes(el: HTMLElement) {
            a11yFixBuefyModalAriaAttrs(el);
        },
    
        handleBuefyNotificationFixes(el: HTMLElement) {
            a11yFixBuefyNotificationAriaAttrs(el);
        }
    },

    /**
     * Computed
     */

    /**
     * Lifecycle
     */
    created() {
        this.componentId = Vue.prototype.MgGetUUID();

        const sessionId = `NETW-${this.id}-LINK`;
        const promises = [
            sessionStore.getSession(sessionId),
            this.fetchMeetingtables()
        ];
        Promise.allSettled(promises).then((response) => {
            const sessionData = response[0];

            if ("value" in sessionData) {
                const roomData = sessionData.value as SessionDataObject;
                this.networkingRoomData = roomData;
            }

            this.handleTablesInterval();

            this.isLoading = false;

            this.logPageVisit();
        });

        eventHub.$on("NetworkingRoomSvgInserted", this.handleRoomItemsInit);
        eventHub.$on(
            "NetworkingRoomElementWithActiveClassToggleInserted",
            this.handleAnimationElement
        );

        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);
        eventHub.$on(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );
    },

    beforeDestroy() {
        eventHub.$off("NetworkingRoomSvgInserted", this.handleRoomItemsInit);
        eventHub.$off(
            "NetworkingRoomElementWithActiveClassToggleInserted",
            this.handleAnimationElement
        );

        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);
        eventHub.$off(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );

        window.clearInterval(this.tablesInterval);
    }

    /**
     * Methods
     */

})
