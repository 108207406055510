<template>
    <svg
        width="1438"
        height="532"
        viewBox="0 0 1438 532"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="Lounge" clip-path="url(#clip0)">
            <g id="Group 60">
                <path
                    id="Vector"
                    d="M10 -164H1438V532H10V-164Z"
                    fill="transparent"
                />
                <path
                    id="Subtract"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M548.098 532C456.33 481.291 400.173 424.176 397.058 368.713C389.73 236.696 685.298 163.842 1057.08 205.647C1159.43 217.138 1257.14 236.207 1344.83 260.654C1368.77 202.225 1381.71 139.639 1381.71 74.1194C1381.71 12.756 1370.23 -46.6516 1348.98 -101.903C1296.22 -126.106 1238.33 -150.798 1177.5 -164H724.87C661.359 -150.309 599.803 -124.639 542.644 -98.725C241.213 37.6925 173.306 294.392 391.196 474.571C416.77 495.703 445.159 514.867 475.832 532H548.098Z"
                    fill="transparent"
                />
                <path
                    id="Subtract_2"
                    d="M397.058 368.713C400.173 424.176 456.33 481.291 548.098 532H475.832C445.159 514.867 416.77 495.703 391.196 474.571C173.306 294.392 241.213 37.6925 542.644 -98.725C599.803 -124.639 661.359 -150.309 724.87 -164H1177.5C1238.33 -150.798 1296.22 -126.106 1348.98 -101.903C1370.23 -46.6516 1381.71 12.756 1381.71 74.1194C1381.71 139.639 1368.77 202.225 1344.83 260.654C1257.14 236.207 1159.43 217.138 1057.08 205.647C685.298 163.842 389.73 236.696 397.058 368.713Z"
                    fill="var(--loungeWallBigDecor)"
                />
                <path
                    id="Subtract_3"
                    d="M391.195 474.571C173.305 294.392 240.969 37.6925 542.643 -98.725C599.803 -124.639 661.359 -150.309 724.87 -164H123.963C86.5891 -93.3465 65.8262 -8.75787 65.8262 74.1194C65.8262 262.485 173.495 429.479 338.926 532H475.832C445.159 514.867 416.77 495.703 391.195 474.571Z"
                    fill="var(--loungeSkylight)"
                />
                <path
                    id="Subtract_4"
                    d="M1108.47 532H548.099C456.331 481.291 400.174 424.176 397.059 368.713C389.731 236.696 685.299 163.842 1057.08 205.892C1159.67 217.382 1257.14 236.451 1344.83 260.899C1299.93 370.741 1216.51 465.065 1108.47 532Z"
                    fill="var(--loungeSkylight)"
                />
                <path
                    id="Vector_2"
                    d="M839.435 62.8735H856.045V497.307H839.435V62.8735Z"
                    fill="var(--loungeWallBigDecor)"
                />
                <path
                    id="Vector_3"
                    d="M545.575 86.3433H562.186V520.777H545.575V86.3433Z"
                    fill="var(--loungeWallBigDecor)"
                />
                <path
                    id="Vector_4"
                    d="M889.752 245.497H1079.06V434.966H889.752V245.497Z"
                    fill="var(--loungeWindowBlinds)"
                />
                <g id="Group">
                    <path
                        id="Vector_5"
                        d="M889.752 434.966H1079.06"
                        stroke="var(--loungeSkylight)"
                        stroke-width="1.8222"
                    />
                    <path
                        id="Vector_6"
                        d="M889.752 413.941H1079.06"
                        stroke="var(--loungeSkylight)"
                        stroke-width="1.8222"
                    />
                    <path
                        id="Vector_7"
                        d="M889.752 392.916H1079.06"
                        stroke="var(--loungeSkylight)"
                        stroke-width="1.8222"
                    />
                    <path
                        id="Vector_8"
                        d="M889.752 371.891H1079.06"
                        stroke="var(--loungeSkylight)"
                        stroke-width="1.8222"
                    />
                    <path
                        id="Vector_9"
                        d="M889.752 350.866H1079.06"
                        stroke="var(--loungeSkylight)"
                        stroke-width="1.8222"
                    />
                    <path
                        id="Vector_10"
                        d="M889.752 329.841H1079.06"
                        stroke="var(--loungeSkylight)"
                        stroke-width="1.8222"
                    />
                    <path
                        id="Vector_11"
                        d="M889.752 308.572H1079.06"
                        stroke="var(--loungeSkylight)"
                        stroke-width="1.8222"
                    />
                    <path
                        id="Vector_12"
                        d="M889.752 287.547H1079.06"
                        stroke="var(--loungeSkylight)"
                        stroke-width="1.8222"
                    />
                    <path
                        id="Vector_13"
                        d="M889.752 266.522H1079.06"
                        stroke="var(--loungeSkylight)"
                        stroke-width="1.8222"
                    />
                    <path
                        id="Vector_14"
                        d="M1079.06 245.497H889.752"
                        stroke="var(--loungeSkylight)"
                        stroke-width="1.8222"
                    />
                </g>
                <path
                    id="Vector_15"
                    d="M1120.83 328.13H1359.97V455.013H1120.83V328.13Z"
                    fill="var(--loungeSkylight)"
                />
                <path
                    id="Vector_16"
                    d="M608.84 295.859H735.617V481.905H608.84V295.859Z"
                    fill="var(--loungeDoor)"
                />
                <path
                    id="Vector_17"
                    d="M1254.7 30.3584H1437.9V479.705H1254.7V30.3584Z"
                    fill="var(--loungeRightPillar)"
                />
                <path
                    id="Vector_18"
                    d="M1284.25 195.624C1284.25 200.024 1287.67 203.447 1292.07 203.447H1336.53C1340.92 203.447 1344.34 200.024 1344.34 195.624V151.374C1344.34 146.973 1340.92 143.551 1336.53 143.551H1292.31C1287.92 143.551 1284.5 146.973 1284.5 151.374V195.624H1284.25Z"
                    fill="var(--loungeWindowDecor)"
                />
                <path
                    id="Vector_19"
                    d="M1336.52 141.106C1342.14 141.106 1346.54 145.751 1346.54 151.129V195.38C1346.54 201.003 1341.9 205.403 1336.52 205.403H1292.31C1286.69 205.403 1282.3 200.758 1282.3 195.38V151.129C1282.3 145.506 1286.94 141.106 1292.31 141.106H1336.52ZM1342.14 195.624V151.374C1342.14 148.196 1339.7 145.751 1336.52 145.751H1292.31C1289.14 145.751 1286.69 148.196 1286.69 151.374V195.624C1286.69 198.802 1289.14 201.247 1292.31 201.247H1336.52C1339.7 201.247 1342.14 198.802 1342.14 195.624Z"
                    fill="var(--loungeWindowDecorBorder)"
                />
                <path
                    id="Vector_20"
                    d="M1284.25 272.389C1284.25 276.79 1287.67 280.212 1292.07 280.212H1336.53C1340.92 280.212 1344.34 276.79 1344.34 272.389V228.139C1344.34 223.739 1340.92 220.316 1336.53 220.316H1292.31C1287.92 220.316 1284.5 223.739 1284.5 228.139V272.389H1284.25Z"
                    fill="var(--loungeWindowDecor)"
                />
                <path
                    id="Vector_21"
                    d="M1336.52 217.871C1342.14 217.871 1346.54 222.516 1346.54 227.895V272.145C1346.54 277.768 1341.9 282.168 1336.52 282.168H1292.31C1286.69 282.168 1282.3 277.523 1282.3 272.145V227.895C1282.3 222.272 1286.94 217.871 1292.31 217.871H1336.52ZM1342.14 272.389V228.139C1342.14 224.961 1339.7 222.516 1336.52 222.516H1292.31C1289.14 222.516 1286.69 224.961 1286.69 228.139V272.389C1286.69 275.568 1289.14 278.012 1292.31 278.012H1336.52C1339.7 278.012 1342.14 275.568 1342.14 272.389Z"
                    fill="var(--loungeWindowDecorBorder)"
                />
                <path
                    id="Vector_22"
                    d="M1299.15 118.859C1299.15 126.193 1305.01 132.305 1312.59 132.305C1319.91 132.305 1326.02 126.437 1326.02 118.859C1326.02 111.524 1320.16 105.412 1312.59 105.412C1305.26 105.657 1299.15 111.524 1299.15 118.859Z"
                    fill="var(--loungeCeiling)"
                />
                <path
                    id="Vector_23"
                    d="M1339.7 118.859C1339.7 126.193 1345.56 132.305 1353.14 132.305C1360.46 132.305 1366.57 126.437 1366.57 118.859C1366.57 111.524 1360.71 105.412 1353.14 105.412C1345.81 105.657 1339.7 111.524 1339.7 118.859Z"
                    fill="var(--loungeCeiling)"
                />
                <path
                    id="Vector_24"
                    d="M200.91 118.859C200.91 126.193 206.772 132.305 214.344 132.305C221.673 132.305 227.779 126.437 227.779 118.859C227.779 111.524 221.917 105.412 214.344 105.412C207.016 105.657 200.91 111.524 200.91 118.859Z"
                    fill="var(--loungeCeiling)"
                />
                <path
                    id="Vector_25"
                    d="M241.458 118.859C241.458 126.193 247.321 132.305 254.893 132.305C262.221 132.305 268.328 126.437 268.328 118.859C268.328 111.524 262.466 105.412 254.893 105.412C247.565 105.657 241.458 111.524 241.458 118.859Z"
                    fill="var(--loungeCeiling)"
                />
                <path
                    id="Subtract_5"
                    d="M65.8279 73.1194C65.8279 -9.51338 86.5909 -94.102 123.964 -165H0.363281V532H340.703C174.268 429.52 65.8279 262.158 65.8279 73.1194Z"
                    fill="var(--loungeLeftPillar)"
                />
                <path
                    id="Vector_26"
                    d="M-1.10324 299.037L481.82 489.728V404.651L-1.10324 213.96V299.037Z"
                    fill="var(--loungeEscalator)"
                />
                <path
                    id="Vector_27"
                    d="M482.797 413.696L481.087 417.852L-1.10394 227.161V223.005L482.797 413.696Z"
                    fill="var(--loungeCeiling)"
                />
                <g id="Group_2">
                    <path
                        id="Vector_28"
                        d="M527.012 392.916C527.012 388.515 530.432 385.093 534.829 385.093H686.033C690.429 385.093 693.849 388.515 693.849 392.916V462.836C693.849 467.237 697.269 470.659 701.666 470.659H852.87C857.266 470.659 860.686 467.237 860.686 462.836V392.916C860.686 388.515 864.106 385.093 868.503 385.093H1019.71C1024.1 385.093 1027.52 388.515 1027.52 392.916V506.597H526.768V392.916H527.012Z"
                        fill="var(--loungeCenterBgElementBorder)"
                        stroke="var(--loungeCenterBgElementStroke)"
                        stroke-width="5"
                    />
                </g>
                <g id="Group_3">
                    <path
                        id="Vector_29"
                        d="M1014.58 506.597C1014.58 506.597 1014.58 408.318 1014.58 398.539C1004.81 398.539 884.135 398.539 874.365 398.539C874.365 407.829 874.365 463.08 874.365 463.08C874.365 474.815 864.838 484.35 853.113 484.35H701.909C690.184 484.35 680.658 474.815 680.658 463.08C680.658 463.08 680.658 407.829 680.658 398.539C670.887 398.539 550.217 398.539 540.446 398.539C540.446 408.562 540.446 506.597 540.446 506.597"
                        fill="var(--loungeCenterBgElement)"
                        stroke="var(--loungeCenterBgElementStroke)"
                        stroke-width="2"
                    />
                </g>
                <g id="Group_4">
                    <path
                        id="Vector_30"
                        d="M686.031 390.716C687.253 390.716 688.23 391.693 688.23 393.16V463.08C688.23 470.415 694.337 476.527 701.665 476.527H852.868C860.197 476.527 866.303 470.415 866.303 463.08V393.16C866.303 391.938 867.28 390.716 868.746 390.716H1019.95C1021.17 390.716 1022.39 391.693 1022.39 393.16V506.842H1020.19V393.16H868.99V463.325C868.99 471.882 861.907 478.971 853.357 478.971H702.153C693.604 478.971 686.52 471.882 686.52 463.325V393.405H535.316V507.086H533.118V393.405C533.118 392.182 534.095 390.96 535.56 390.96H686.031V390.716Z"
                        fill="var(--loungeCenterBgElementBorder)"
                        stroke="var(--loungeCenterBgElementStroke)"
                        stroke-width="2"
                    />
                </g>
                <g id="Group_5">
                    <g id="Group_6">
                        <g id="Group_7">
                            <path
                                id="Vector_31"
                                d="M-0.999878 0H1438V98H-0.999878V0Z"
                                fill="var(--loungeCeiling)"
                            />
                        </g>
                    </g>
                    <g id="Group_8">
                        <path
                            id="Vector_32"
                            d="M712.9 88.5436H404.386L383.867 63.1182H710.458L712.9 88.5436Z"
                            fill="var(--loungeWall)"
                        />
                        <path
                            id="Vector_33"
                            d="M711.922 77.7867H404.385L392.415 63.1182H710.456L711.922 77.7867Z"
                            fill="var(--loungeWall2)"
                        />
                        <path
                            id="Vector_34"
                            d="M829.906 88.5436H1138.18L1158.94 63.1182H832.349L829.906 88.5436Z"
                            fill="var(--loungeWall)"
                        />
                        <path
                            id="Vector_35"
                            d="M830.884 77.7867H1138.18L1150.39 63.1182H832.35L830.884 77.7867Z"
                            fill="var(--loungeWall2)"
                        />
                        <g id="Group_9">
                            <path
                                id="Vector_36"
                                d="M294.465 88.5436H-1.10303V63.1182H265.152L294.465 88.5436Z"
                                fill="var(--loungeWall)"
                            />
                            <path
                                id="Vector_37"
                                d="M282.252 77.7867H-1.10242V63.1182H265.153L282.252 77.7867Z"
                                fill="var(--loungeWall2)"
                            />
                        </g>
                    </g>
                </g>
                <path
                    id="Vector_38"
                    d="M320.602 -164H322.801V137.194H320.602V-164Z"
                    fill="var(--loungeLampHanger)"
                />
                <path
                    id="Vector_39"
                    d="M313.274 109.08V83.6541H329.885V109.08C372.877 111.769 406.586 134.016 406.586 161.397C406.586 190.49 368.48 213.96 321.335 213.96C274.191 213.96 236.085 190.49 236.085 161.397C236.573 134.261 270.283 111.769 313.274 109.08Z"
                    fill="white"
                />
                <g id="Group_10">
                    <path
                        id="Vector_40"
                        d="M1050.73 35.2478H1052.93V183.889H1050.73V35.2478Z"
                        fill="var(--loungeLampHanger)"
                    />
                    <path
                        id="Vector_41"
                        d="M1046.82 167.754V153.085H1056.35V167.754C1081.26 169.221 1100.8 182.178 1100.8 198.069C1100.8 214.938 1078.82 228.384 1051.7 228.384C1024.59 228.384 1002.61 214.693 1002.61 198.069C1002.61 182.178 1021.9 169.221 1046.82 167.754Z"
                        fill="white"
                    />
                </g>
                <g id="Group_11">
                    <path
                        id="Vector_42"
                        d="M1160.9 0H1163.09V240H1160.9V0Z"
                        fill="var(--loungeLampHanger)"
                    />
                    <path
                        id="Vector_43"
                        d="M1158.94 249.409V240.363H1164.8V249.409C1180.19 250.387 1192.16 258.21 1192.16 267.989C1192.16 278.257 1178.48 286.813 1161.87 286.813C1145.02 286.813 1131.58 278.501 1131.58 267.989C1131.58 258.21 1143.55 250.387 1158.94 249.409Z"
                        fill="white"
                    />
                </g>
                <path
                    id="Vector_44"
                    d="M752.474,505.6165122241974 H1437.9 V532.2645122241973 H752.474 V505.6165122241974 z"
                    fill="var(--loungeAccent)"
                />
                <path
                    id="Vector_45"
                    d="M1297.69 155.53H1437.9V179.977H1297.69V155.53Z"
                    fill="var(--loungeWindowDecorBar)"
                />
                <g id="Group_12">
                    <path
                        id="Vector_46"
                        d="M235.35 250.631C235.35 255.032 231.93 258.454 227.533 258.454H183.32C178.923 258.454 175.503 255.032 175.503 250.631V206.381C175.503 201.98 178.923 198.558 183.32 198.558H227.533C231.93 198.558 235.35 201.98 235.35 206.381V250.631Z"
                        fill="var(--loungeWindowDecor)"
                    />
                    <path
                        id="Vector_47"
                        d="M227.29 196.113C232.908 196.113 237.305 200.758 237.305 206.136V250.386C237.305 256.009 232.664 260.41 227.29 260.41H183.076C177.458 260.41 173.061 255.765 173.061 250.386V206.136C173.061 200.513 177.702 196.113 183.076 196.113H227.29ZM233.152 250.631V206.381C233.152 203.203 230.709 200.758 227.534 200.758H183.321C180.145 200.758 177.702 203.203 177.702 206.381V250.631C177.702 253.809 180.145 256.254 183.321 256.254H227.534C230.465 256.254 233.152 253.809 233.152 250.631Z"
                        fill="var(--loungeWindowDecorBorder)"
                    />
                    <path
                        id="Vector_48"
                        d="M125.183 210.537H221.914V234.984H125.183V210.537Z"
                        fill="var(--loungeWindowDecorBar)"
                    />
                </g>
                <path
                    id="Vector_49"
                    d="M1297.69 232.54H1437.9V256.987H1297.69V232.54Z"
                    fill="var(--loungeWindowDecorBar)"
                />
                <path
                    id="Vector_50"
                    d="M-1 490H769V532H-1V490Z"
                    fill="var(--loungeAccent)"
                />
                <g id="Group_13">
                    <g id="Group_14">
                        <path
                            id="Vector_51"
                            d="M322.798 113.236V83.6541H330.127V109.08C338.188 109.568 345.76 110.791 352.844 112.502V115.925C339.653 114.947 329.882 113.969 322.798 113.236Z"
                            fill="var(--loungeLamp1)"
                        />
                        <path
                            id="Vector_52"
                            d="M310.097 109.324C311.074 109.324 312.051 109.08 313.272 109.08V83.6541H322.799V113.236C301.059 111.035 303.746 109.813 310.097 109.324Z"
                            fill="var(--loungeLampHanger)"
                        />
                        <g id="Group_15">
                            <path
                                id="Vector_53"
                                d="M330.127 109.08C373.119 111.769 406.828 134.016 406.828 161.397C406.828 190.49 368.722 213.96 321.578 213.96C274.433 213.96 236.327 190.49 236.327 161.397C236.327 134.016 270.036 111.524 313.028 109.08"
                                fill="var(--loungeLamp1)"
                            />
                            <g id="Group_16">
                                <g id="Group_17">
                                    <path
                                        id="Vector_54"
                                        d="M376.294 161.397C376.294 137.928 359.684 117.881 335.745 109.568C376.05 113.724 406.584 135.483 406.584 161.397C406.584 187.556 375.806 209.07 335.501 213.226C359.684 204.914 376.294 184.867 376.294 161.397Z"
                                        fill="transparent"
                                    />
                                </g>
                                <g id="Group_18">
                                    <path
                                        id="Vector_55"
                                        d="M376.294 161.397C376.294 137.928 359.684 117.881 335.745 109.568C376.05 113.724 406.584 135.483 406.584 161.397C406.584 187.556 375.806 209.07 335.501 213.226C359.684 204.914 376.294 184.867 376.294 161.397Z"
                                        fill="var(--loungeLamp2)"
                                    />
                                </g>
                            </g>
                            <g id="Group_19">
                                <path
                                    id="Vector_56"
                                    d="M386.309 161.397C386.309 190.979 357.24 214.937 321.577 214.937V213.226C356.263 213.226 384.599 190.001 384.599 161.397C384.599 134.505 360.172 112.502 327.684 109.813L327.928 108.102C361.393 111.035 386.309 133.772 386.309 161.397Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_57"
                                    d="M365.059 161.397C365.059 190.979 345.517 214.937 321.578 214.937V213.226C344.54 213.226 363.349 190.001 363.349 161.397C363.349 134.505 347.227 112.502 325.731 109.813L325.975 108.102C348.204 111.035 365.059 133.772 365.059 161.397Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_58"
                                    d="M343.805 161.397C343.805 191.468 334.034 214.937 321.576 214.937V213.226C332.813 213.226 342.095 190.001 342.095 161.397C342.095 134.994 334.034 112.258 323.53 109.813L324.019 108.102C335.255 111.035 343.805 133.772 343.805 161.397Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_59"
                                    d="M320.845 109.08H322.555V214.204H320.845V109.08Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_60"
                                    d="M319.866 109.813C309.362 112.258 301.302 134.994 301.302 161.397C301.302 190.001 310.584 213.226 321.82 213.226V214.937C309.363 214.937 299.592 191.468 299.592 161.397C299.592 133.772 308.141 111.035 319.378 108.102L319.866 109.813Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_61"
                                    d="M317.668 109.813C296.172 112.502 280.05 134.505 280.05 161.397C280.05 190.001 298.859 213.226 321.82 213.226V214.937C297.882 214.937 278.34 190.979 278.34 161.397C278.34 133.772 295.195 110.791 317.423 108.102L317.668 109.813Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_62"
                                    d="M315.469 109.813C282.981 112.502 258.554 134.505 258.554 161.397C258.554 190.001 286.89 213.226 321.576 213.226V214.937C285.913 214.937 256.844 190.979 256.844 161.397C256.844 133.772 282.004 110.791 315.225 108.102L315.469 109.813Z"
                                    fill="var(--loungeLampLines)"
                                />
                            </g>
                        </g>
                    </g>
                    <g id="Group_20">
                        <path
                            id="Vector_63"
                            d="M1052.44 167.02V148.685H1056.83V164.331C1061.72 164.576 1066.61 165.309 1071 166.531V168.487C1062.94 167.998 1056.83 167.509 1052.44 167.02Z"
                            fill="var(--loungeLamp1)"
                        />
                        <path
                            id="Vector_64"
                            d="M1044.62 164.576C1045.35 164.576 1045.84 164.576 1046.58 164.331V148.685H1052.44V167.02C1038.76 165.798 1040.71 165.064 1044.62 164.576Z"
                            fill="var(--loungeLampHanger)"
                        />
                        <g id="Group_21">
                            <path
                                id="Vector_65"
                                d="M1056.83 164.331C1083.7 166.042 1104.47 179.978 1104.47 196.846C1104.47 214.938 1080.77 229.606 1051.46 229.606C1022.15 229.606 998.453 214.938 998.453 196.846C998.453 179.978 1019.46 165.798 1046.09 164.331"
                                fill="var(--loungeLamp1)"
                            />
                            <g id="Group_22">
                                <g id="Group_23">
                                    <path
                                        id="Vector_66"
                                        d="M1085.66 197.091C1085.66 182.422 1075.15 169.954 1060.5 164.82C1085.41 167.509 1104.47 180.955 1104.47 197.091C1104.47 213.226 1085.41 226.672 1060.5 229.361C1075.4 223.983 1085.66 211.515 1085.66 197.091Z"
                                        fill="transparent"
                                    />
                                </g>
                                <g id="Group_24">
                                    <path
                                        id="Vector_67"
                                        d="M1085.66 197.091C1085.66 182.422 1075.15 169.954 1060.5 164.82C1085.41 167.509 1104.47 180.955 1104.47 197.091C1104.47 213.226 1085.41 226.672 1060.5 229.361C1075.4 223.983 1085.66 211.515 1085.66 197.091Z"
                                        fill="var(--loungeLamp2)"
                                    />
                                </g>
                            </g>
                            <g id="Group_25">
                                <path
                                    id="Vector_68"
                                    d="M1092.01 197.091C1092.01 215.426 1073.93 230.339 1051.71 230.339V229.117C1073.2 229.117 1090.79 214.693 1090.79 197.091C1090.79 180.466 1075.64 166.776 1055.61 165.064V163.842C1076.38 165.553 1092.01 179.733 1092.01 197.091Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_69"
                                    d="M1078.82 197.091C1078.82 215.426 1066.6 230.339 1051.7 230.339V229.117C1065.87 229.117 1077.6 214.693 1077.6 197.091C1077.6 180.466 1067.58 166.776 1054.15 165.064L1054.39 163.842C1068.31 165.553 1078.82 179.733 1078.82 197.091Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_70"
                                    d="M1065.38 197.091C1065.38 215.671 1059.28 230.339 1051.7 230.339V229.117C1058.79 229.117 1064.41 214.693 1064.41 197.091C1064.41 180.711 1059.28 166.531 1052.92 165.064L1053.17 164.086C1060.25 165.553 1065.38 179.733 1065.38 197.091Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_71"
                                    d="M1052.19 164.331V210.781C1052.19 229.85 1052.19 229.85 1051.95 229.85L1050.97 229.361V164.086H1052.19V164.331Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_72"
                                    d="M1050.48 165.064C1044.13 166.531 1039 180.711 1039 197.091C1039 214.693 1044.62 229.117 1051.71 229.117V230.339C1043.89 230.339 1038.03 215.671 1038.03 197.091C1038.03 179.977 1043.4 165.798 1050.48 164.086V165.064Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_73"
                                    d="M1049.26 165.064C1035.83 166.776 1025.81 180.466 1025.81 197.091C1025.81 214.693 1037.54 229.117 1051.71 229.117V230.339C1036.8 230.339 1024.59 215.426 1024.59 197.091C1024.59 179.977 1035.09 165.553 1049.02 164.086L1049.26 165.064Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_74"
                                    d="M1047.8 165.064C1027.77 166.776 1012.62 180.466 1012.62 197.091C1012.62 214.693 1030.21 229.117 1051.71 229.117V230.339C1029.48 230.339 1011.4 215.426 1011.4 197.091C1011.4 179.977 1027.03 165.553 1047.8 164.086V165.064Z"
                                    fill="var(--loungeLampLines)"
                                />
                            </g>
                        </g>
                    </g>
                    <g id="Group_26">
                        <path
                            id="Vector_75"
                            d="M1162.36 250.631V239.63H1165.04V249.164C1167.98 249.409 1170.91 249.898 1173.59 250.387V251.609C1168.71 251.364 1165.04 250.876 1162.36 250.631Z"
                            fill="var(--loungeLamp1)"
                        />
                        <path
                            id="Vector_76"
                            d="M1157.72 249.164C1158.21 249.164 1158.45 249.164 1158.94 249.164V239.63H1162.36V250.631C1154.3 249.898 1155.28 249.409 1157.72 249.164Z"
                            fill="var(--loungeLampHanger)"
                        />
                        <g id="Group_27">
                            <path
                                id="Vector_77"
                                d="M1165.05 249.164C1181.17 250.142 1193.62 258.454 1193.62 268.722C1193.62 279.479 1179.46 288.28 1161.87 288.28C1144.28 288.28 1130.11 279.479 1130.11 268.722C1130.11 258.454 1142.57 250.142 1158.69 249.164"
                                fill="var(--loungeLamp1)"
                            />
                            <g id="Group_28">
                                <path
                                    id="Vector_78"
                                    d="M1182.39 268.722C1182.39 259.921 1176.04 252.587 1167.24 249.409C1182.14 250.876 1193.62 258.943 1193.62 268.722C1193.62 278.501 1182.14 286.569 1167.24 288.036C1176.04 284.858 1182.39 277.523 1182.39 268.722Z"
                                    fill="var(--loungeLamp2)"
                                />
                            </g>
                            <g id="Group_29">
                                <path
                                    id="Vector_79"
                                    d="M1186.05 268.722C1186.05 279.724 1175.3 288.769 1161.87 288.769V288.036C1174.82 288.036 1185.32 279.479 1185.32 268.722C1185.32 258.699 1176.28 250.387 1164.07 249.653V248.92C1176.77 249.898 1186.05 258.454 1186.05 268.722Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_80"
                                    d="M1177.99 268.722C1177.99 279.724 1170.66 288.769 1161.87 288.769V288.036C1170.42 288.036 1177.5 279.479 1177.5 268.722C1177.5 258.699 1171.4 250.387 1163.58 249.653V248.92C1171.89 249.898 1177.99 258.454 1177.99 268.722Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_81"
                                    d="M1170.18 268.722C1170.18 279.968 1166.51 288.769 1161.87 288.769V288.036C1166.02 288.036 1169.44 279.479 1169.44 268.722C1169.44 258.943 1166.51 250.387 1162.6 249.409L1162.85 248.675C1167 249.898 1170.18 258.454 1170.18 268.722Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_82"
                                    d="M1162.12 249.164V277.035C1162.12 288.525 1162.12 288.525 1162.12 288.525L1161.63 288.28V249.164H1162.12Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_83"
                                    d="M1161.14 249.409C1157.23 250.387 1154.3 258.699 1154.3 268.722C1154.3 279.235 1157.72 288.036 1161.87 288.036V288.769C1157.23 288.769 1153.56 279.968 1153.56 268.722C1153.56 258.454 1156.74 249.898 1160.89 248.92L1161.14 249.409Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_84"
                                    d="M1160.41 249.409C1152.34 250.387 1146.48 258.699 1146.48 268.478C1146.48 278.99 1153.57 287.791 1162.12 287.791V288.525C1153.08 288.525 1145.99 279.479 1145.99 268.478C1145.99 258.21 1152.34 249.653 1160.65 248.675L1160.41 249.409Z"
                                    fill="var(--loungeLampLines)"
                                />
                                <path
                                    id="Vector_85"
                                    d="M1159.67 249.409C1147.7 250.387 1138.42 258.699 1138.42 268.478C1138.42 278.99 1148.92 287.791 1161.87 287.791V288.525C1148.68 288.525 1137.69 279.479 1137.69 268.478C1137.69 258.21 1146.97 249.653 1159.43 248.675L1159.67 249.409Z"
                                    fill="var(--loungeLampLines)"
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <path
                    id="Vector_86"
                    d="M704.84 335.22C704.84 348.666 709.725 359.667 715.588 359.667C721.45 359.667 726.336 348.666 726.336 335.22C726.336 321.773 721.45 310.772 715.588 310.772C709.725 311.016 704.84 321.773 704.84 335.22Z"
                    fill="var(--loungeCeiling)"
                />
                <path
                    id="Vector_87"
                    d="M443.47 200.758C443.47 217.382 456.905 230.829 473.515 230.829C490.126 230.829 503.561 217.382 503.561 200.758C503.561 184.134 490.126 170.688 473.515 170.688C456.905 170.688 443.47 184.134 443.47 200.758Z"
                    fill="var(--loungeCeiling)"
                />
                <path
                    id="Vector_88"
                    d="M375.563 254.298C375.563 264.322 383.624 272.145 393.395 272.145C403.41 272.145 411.227 264.077 411.227 254.298C411.227 244.519 403.166 236.451 393.395 236.451C383.624 236.207 375.563 244.275 375.563 254.298Z"
                    fill="var(--loungeCeiling)"
                />
                <g id="Group_30">
                    <path
                        id="Vector_89"
                        d="M613.726 123.993C613.726 131.327 619.588 137.439 627.161 137.439C634.489 137.439 640.596 131.571 640.596 123.993C640.596 116.658 634.733 110.546 627.161 110.546C619.833 110.546 613.726 116.658 613.726 123.993Z"
                        fill="var(--loungeCeiling)"
                    />
                    <path
                        id="Vector_90"
                        d="M654.275 123.993C654.275 131.327 660.137 137.439 667.71 137.439C675.038 137.439 681.145 131.571 681.145 123.993C681.145 116.658 675.282 110.546 667.71 110.546C660.381 110.546 654.275 116.658 654.275 123.993Z"
                        fill="var(--loungeCeiling)"
                    />
                    <path
                        id="Vector_91"
                        d="M695.07 123.993C695.07 131.327 700.932 137.439 708.505 137.439C715.833 137.439 721.939 131.571 721.939 123.993C721.939 116.658 716.077 110.546 708.505 110.546C700.932 110.546 695.07 116.658 695.07 123.993Z"
                        fill="var(--loungeCeiling)"
                    />
                    <path
                        id="Vector_92"
                        d="M735.617 123.993C735.617 131.327 741.479 137.439 749.051 137.439C756.38 137.439 762.486 131.571 762.486 123.993C762.486 116.658 756.624 110.546 749.051 110.546C741.479 110.546 735.617 116.658 735.617 123.993Z"
                        fill="var(--loungeCeiling)"
                    />
                    <path
                        id="Vector_93"
                        d="M776.165 123.993C776.165 131.327 782.028 137.439 789.6 137.439C796.928 137.439 803.035 131.571 803.035 123.993C803.035 116.658 797.173 110.546 789.6 110.546C782.272 110.546 776.165 116.658 776.165 123.993Z"
                        fill="var(--loungeCeiling)"
                    />
                    <path
                        id="Vector_94"
                        d="M816.714 123.993C816.714 131.327 822.577 137.439 830.149 137.439C837.477 137.439 843.584 131.571 843.584 123.993C843.584 116.658 837.721 110.546 830.149 110.546C822.821 110.546 816.714 116.658 816.714 123.993Z"
                        fill="var(--loungeCeiling)"
                    />
                    <path
                        id="Vector_95"
                        d="M857.509 123.993C857.509 131.327 863.372 137.439 870.944 137.439C878.272 137.439 884.379 131.571 884.379 123.993C884.379 116.658 878.516 110.546 870.944 110.546C863.372 110.546 857.509 116.658 857.509 123.993Z"
                        fill="var(--loungeCeiling)"
                    />
                    <path
                        id="Vector_96"
                        d="M898.058 123.993C898.058 131.327 903.92 137.439 911.493 137.439C918.821 137.439 924.928 131.571 924.928 123.993C924.928 116.658 919.065 110.546 911.493 110.546C903.92 110.546 898.058 116.658 898.058 123.993Z"
                        fill="var(--loungeCeiling)"
                    />
                    <path
                        id="Vector_97"
                        d="M938.607 123.993C938.607 131.327 944.469 137.439 952.042 137.439C959.37 137.439 965.477 131.571 965.477 123.993C965.477 116.658 959.614 110.546 952.042 110.546C944.714 110.546 938.607 116.658 938.607 123.993Z"
                        fill="var(--loungeCeiling)"
                    />
                    <path
                        id="Vector_98"
                        d="M979.4 123.993C979.4 131.327 985.262 137.439 992.835 137.439C1000.16 137.439 1006.27 131.571 1006.27 123.993C1006.27 116.658 1000.41 110.546 992.835 110.546C985.262 110.546 979.4 116.658 979.4 123.993Z"
                        fill="var(--loungeCeiling)"
                    />
                </g>
                <g id="Group_31">
                    <path
                        id="Vector_99"
                        d="M1263.73 88.5436H1437.9V63.1182H1285.72L1263.73 88.5436Z"
                        fill="var(--loungeWall)"
                    />
                    <path
                        id="Vector_100"
                        d="M1273.02 77.7867H1437.9V63.1182H1285.72L1273.02 77.7867Z"
                        fill="var(--loungeWall2)"
                    />
                </g>
                <g id="Light">
                    <path
                        id="Vector_101"
                        d="M627.716 56.5171V26.9355H635.045V52.361C643.105 52.85 650.678 54.0724 657.762 55.7837V59.2064C644.571 57.984 634.8 57.2505 627.716 56.5171Z"
                        fill="var(--loungeLamp1)"
                    />
                    <path
                        id="Vector_102"
                        d="M615.015 52.6055C615.992 52.6055 616.969 52.361 618.19 52.361V26.9355H627.717V56.5171C605.732 54.3168 608.664 53.0945 615.015 52.6055Z"
                        fill="var(--loungeLampHanger)"
                    />
                    <g id="Group_32">
                        <path
                            id="Vector_103"
                            d="M634.801 52.3608C677.793 55.0501 711.502 77.2974 711.502 104.679C711.502 133.771 673.396 157.241 626.251 157.241C579.107 157.241 541.001 133.771 541.001 104.679C541.001 77.2974 574.71 54.8056 617.702 52.3608"
                            fill="var(--loungeLamp1)"
                        />
                        <g id="Group_33">
                            <path
                                id="Vector_104"
                                d="M681.212 104.679C681.212 81.2091 664.602 61.162 640.663 52.8499C680.968 57.0059 711.502 78.7643 711.502 104.679C711.502 130.838 680.724 152.352 640.419 156.508C664.358 148.195 681.212 128.148 681.212 104.679Z"
                                fill="var(--loungeLamp2)"
                            />
                        </g>
                        <g id="Group_34">
                            <path
                                id="Vector_105"
                                d="M691.229 104.679C691.229 134.26 662.16 158.219 626.497 158.219V156.508C661.183 156.508 689.519 133.282 689.519 104.679C689.519 77.7864 665.092 55.7836 632.603 53.0944L632.848 51.3831C666.069 54.0723 691.229 77.053 691.229 104.679Z"
                                fill="var(--loungeLampLines)"
                            />
                            <path
                                id="Vector_106"
                                d="M669.977 104.679C669.977 134.26 650.435 158.219 626.496 158.219V156.508C649.458 156.508 668.267 133.282 668.267 104.679C668.267 77.7864 652.145 55.7836 630.649 53.0944L630.893 51.3831C653.122 54.0723 669.977 77.053 669.977 104.679Z"
                                fill="var(--loungeLampLines)"
                            />
                            <path
                                id="Vector_107"
                                d="M648.725 104.679C648.725 134.749 638.954 158.219 626.496 158.219V156.508C637.732 156.508 647.015 133.282 647.015 104.679C647.015 78.2754 638.954 55.5392 628.45 53.0944L628.939 51.3831C640.175 54.3168 648.725 77.053 648.725 104.679Z"
                                fill="var(--loungeLampLines)"
                            />
                            <path
                                id="Vector_108"
                                d="M627.229 52.3608V142.083C627.229 158.219 627.229 158.219 626.496 158.219L625.763 157.485V52.3608H627.229Z"
                                fill="var(--loungeLampLines)"
                            />
                            <path
                                id="Vector_109"
                                d="M624.542 53.0944C614.038 55.5392 605.977 78.2754 605.977 104.679C605.977 133.282 615.26 156.508 626.496 156.508V158.219C614.038 158.219 604.267 134.749 604.267 104.679C604.267 77.053 612.817 54.3168 624.053 51.3831L624.542 53.0944Z"
                                fill="var(--loungeLampLines)"
                            />
                            <path
                                id="Vector_110"
                                d="M622.343 53.0944C600.848 55.7836 584.726 77.7864 584.726 104.679C584.726 133.282 603.535 156.508 626.496 156.508V158.219C602.558 158.219 583.016 134.26 583.016 104.679C583.016 77.053 599.871 54.0723 622.099 51.3831L622.343 53.0944Z"
                                fill="var(--loungeLampLines)"
                            />
                            <path
                                id="Vector_111"
                                d="M620.389 53.0944C587.901 55.7836 563.474 77.7864 563.474 104.679C563.474 133.282 591.81 156.508 626.496 156.508V158.219C590.832 158.219 561.764 134.26 561.764 104.679C561.764 77.053 586.924 54.0723 620.145 51.3831L620.389 53.0944Z"
                                fill="var(--loungeLampLines)"
                            />
                        </g>
                    </g>
                </g>
                <g id="Group_35">
                    <g id="Group_36">
                        <path
                            id="Vector_112"
                            d="M1437.9 515.643H1048.53L1040.96 517.843H1437.9V515.643Z"
                            fill="var(--loungeLadderStepsSurface)"
                        />
                    </g>
                    <g id="Group_37">
                        <path
                            id="Vector_113"
                            d="M1437.9 500.485H1053.9L1046.58 502.685H1437.9V500.485Z"
                            fill="var(--loungeLadderStepsSurface)"
                        />
                    </g>
                    <g id="Group_38">
                        <path
                            id="Vector_114"
                            d="M1437.9 485.328H1059.52L1052.19 487.528H1437.9V485.328Z"
                            fill="var(--loungeLadderStepsSurface)"
                        />
                    </g>
                    <g id="Group_39">
                        <g id="Group_40">
                            <path
                                id="Vector_115"
                                d="M1437.9 470.17H1065.14L1057.57 472.371H1437.9V470.17Z"
                                fill="var(--loungeLadderStepsSurface)"
                            />
                        </g>
                        <path
                            id="Vector_116"
                            d="M1437.9 455.013H1070.76L1063.19 457.213H1437.9V455.013Z"
                            fill="var(--loungeLadderStepsSurface)"
                        />
                    </g>
                </g>
                <path
                    id="Vector_117"
                    d="M1041 518H1438V532H1041V518Z"
                    fill="var(--loungeLadderSteps)"
                />
                <g id="Group_41">
                    <path
                        id="Vector_118"
                        d="M1063.19 457.213H1437.9V470.659H1063.19V457.213Z"
                        fill="var(--loungeLadderSteps)"
                    />
                </g>
                <path
                    id="Vector_119"
                    d="M1046.58 502.686H1437.9V516.132H1046.58V502.686Z"
                    fill="var(--loungeLadderSteps)"
                />
                <path
                    id="Vector_120"
                    d="M1052.19 487.528H1437.9V500.974H1052.19V487.528Z"
                    fill="var(--loungeLadderSteps)"
                />
                <path
                    id="Vector_121"
                    d="M1057.57 472.371H1437.9V485.817H1057.57V472.371Z"
                    fill="var(--loungeLadderSteps)"
                />
                <path
                    id="Vector_122"
                    d="M-1 499H769V532H-1V499Z"
                    fill="var(--loungeFloorBase)"
                />
                <g id="Setting">
                    <g id="chair">
                        <path
                            id="Vector_123"
                            d="M1328.07 438.545C1328.07 430.688 1336.75 422.625 1328.69 422.625C1328.49 422.625 1328.9 422.625 1328.69 422.625C1328.49 422.625 1328.9 422.625 1328.69 422.625C1320.43 422.625 1329.31 430.481 1329.31 438.545C1329.31 446.402 1335.92 453.018 1344.19 453.018C1344.39 453.018 1344.6 453.018 1344.81 453.018V454.052H1312.58V453.018C1312.78 453.018 1312.99 453.018 1313.2 453.018C1321.46 452.811 1328.07 446.402 1328.07 438.545Z"
                            fill="var(--loungeChairStand)"
                        />
                        <path
                            id="Vector_124"
                            d="M1287.38 399.674C1292.54 396.366 1301.84 400.501 1306.38 403.189C1310.72 405.67 1314.64 405.877 1316.09 399.261C1317.54 392.438 1311.96 380.239 1308.03 374.037C1304.32 367.627 1301.01 359.15 1303.7 355.428C1306.59 351.5 1313.2 354.188 1328.49 357.289C1344.6 360.597 1353.48 356.669 1356.79 354.601C1359.47 352.947 1361.33 349.639 1362.57 349.432C1362.99 349.226 1363.19 349.432 1363.6 350.673C1364.84 354.395 1366.5 386.649 1361.95 407.531C1357.41 428.414 1342.53 440.819 1328.69 441.439C1309.48 442.473 1294.4 432.342 1287.79 418.489C1284.48 411.666 1283.45 402.156 1287.38 399.674Z"
                            fill="var(--loungeChairTop)"
                        />
                        <path
                            id="Vector_125"
                            d="M1291.51 422.831C1297.5 428 1307 429.241 1315.47 423.038C1323.94 416.835 1329.11 406.497 1337.57 400.708C1345.84 394.919 1350.59 399.261 1352.04 400.915C1353.48 402.569 1359.27 410.012 1360.92 395.126C1362.37 381.893 1361.33 359.77 1361.33 356.462C1361.33 351.913 1362.57 346.745 1363.6 350.466C1364.64 354.188 1366.5 386.442 1361.95 407.324C1357.41 428.207 1342.53 440.612 1328.69 441.233C1309.07 442.266 1293.78 431.722 1287.38 417.456"
                            fill="var(--loungeChairBottom)"
                        />
                    </g>
                    <g id="chair_2">
                        <path
                            id="Vector_126"
                            d="M1161.99 438.545C1161.99 430.688 1153.31 422.625 1161.37 422.625C1161.58 422.625 1161.16 422.625 1161.37 422.625C1161.58 422.625 1161.16 422.625 1161.37 422.625C1169.63 422.625 1160.75 430.481 1160.75 438.545C1160.75 446.402 1154.14 453.018 1145.88 453.018C1145.67 453.018 1145.46 453.018 1145.26 453.018V454.052H1177.48V453.018C1177.28 453.018 1177.07 453.018 1176.86 453.018C1168.6 452.811 1161.99 446.402 1161.99 438.545Z"
                            fill="var(--loungeChairStand)"
                        />
                        <path
                            id="Vector_127"
                            d="M1202.69 399.674C1197.52 396.366 1188.23 400.501 1183.68 403.189C1179.34 405.67 1175.42 405.877 1173.97 399.261C1172.53 392.438 1178.1 380.239 1182.03 374.037C1185.75 367.627 1189.05 359.15 1186.37 355.428C1183.47 351.5 1176.86 354.188 1161.58 357.289C1145.46 360.597 1136.58 356.669 1133.27 354.601C1130.59 352.947 1128.73 349.639 1127.49 349.432C1127.08 349.226 1126.87 349.432 1126.46 350.673C1125.22 354.395 1123.57 386.649 1128.11 407.531C1132.66 428.414 1147.53 440.819 1161.37 441.439C1180.58 442.473 1195.66 432.342 1202.27 418.489C1205.58 411.666 1206.61 402.156 1202.69 399.674Z"
                            fill="var(--loungeChairTop)"
                        />
                        <path
                            id="Vector_128"
                            d="M1198.56 422.831C1192.56 428 1183.06 429.241 1174.59 423.038C1166.12 416.835 1160.96 406.497 1152.49 400.708C1144.22 394.919 1139.47 399.261 1138.03 400.915C1136.58 402.569 1130.8 410.012 1129.14 395.126C1127.7 381.893 1128.73 359.77 1128.73 356.462C1128.73 351.913 1127.49 346.745 1126.46 350.466C1125.43 354.188 1123.57 386.442 1128.11 407.324C1132.66 428.207 1147.53 440.612 1161.37 441.233C1181 442.266 1196.28 431.722 1202.69 417.456"
                            fill="var(--loungeChairBottom)"
                        />
                    </g>
                    <g id="Group_42">
                        <path
                            id="Vector_129"
                            d="M1244.12 400.154V445.571C1246.03 450.128 1254.1 453.655 1263.65 453.655C1263.94 453.655 1264.24 453.655 1264.53 453.655V454.39H1221.21V453.655C1221.5 453.655 1221.8 453.655 1222.09 453.655C1231.63 453.655 1239.57 450.275 1241.62 445.571V400.154"
                            fill="var(--loungeTableStand)"
                        />
                        <g id="Group_43">
                            <path
                                id="Vector_130"
                                d="M1211.22 399.125H1274.52V401.623H1211.22V399.125Z"
                                fill="var(--loungeTableBase)"
                            />
                            <path
                                id="Vector_131"
                                d="M1211.22 398.243H1274.52V399.125H1211.22V398.243Z"
                                fill="var(--loungeTableTop)"
                            />
                        </g>
                    </g>
                </g>
                <g id="Setting_2">
                    <g id="chair_3">
                        <path
                            id="Vector_132"
                            d="M989.308 516.353C989.308 508.497 997.985 500.433 989.928 500.433C989.721 500.433 990.135 500.433 989.928 500.433C989.721 500.433 990.135 500.433 989.928 500.433C981.665 500.433 990.548 508.29 990.548 516.353C990.548 524.21 997.158 530.826 1005.42 530.826C1005.63 530.826 1005.83 530.826 1006.04 530.826V531.86H973.814V530.826C974.021 530.826 974.228 530.826 974.434 530.826C982.698 530.62 989.308 524.21 989.308 516.353Z"
                            fill="var(--loungeChairStand)"
                        />
                        <path
                            id="Vector_133"
                            d="M948.612 477.483C953.776 474.175 963.073 478.31 967.617 480.998C971.956 483.479 975.881 483.686 977.327 477.07C978.773 470.247 973.195 458.048 969.27 451.845C965.552 445.436 962.246 436.959 964.932 433.237C967.824 429.309 974.435 431.996 989.722 435.098C1005.84 438.406 1014.72 434.477 1018.02 432.41C1020.71 430.756 1022.57 427.448 1023.81 427.241C1024.22 427.034 1024.43 427.241 1024.84 428.482C1026.08 432.203 1027.73 464.457 1023.19 485.34C1018.64 506.222 1003.77 518.628 989.928 519.248C970.716 520.282 955.636 510.151 949.025 496.298C945.72 489.475 944.687 479.964 948.612 477.483Z"
                            fill="var(--loungeChairTop)"
                        />
                        <path
                            id="Vector_134"
                            d="M952.743 500.64C958.734 505.809 968.237 507.049 976.707 500.847C985.177 494.644 990.341 484.306 998.811 478.517C1007.07 472.728 1011.83 477.069 1013.27 478.724C1014.72 480.378 1020.5 487.821 1022.16 472.934C1023.6 459.702 1022.57 437.579 1022.57 434.271C1022.57 429.722 1023.81 424.553 1024.84 428.275C1025.87 431.996 1027.73 464.25 1023.19 485.133C1018.64 506.015 1003.77 518.421 989.928 519.041C970.303 520.075 955.015 509.53 948.611 495.264"
                            fill="var(--loungeChairBottom)"
                        />
                    </g>
                    <g id="chair_4">
                        <path
                            id="Vector_135"
                            d="M823.227 516.353C823.227 508.497 814.55 500.433 822.607 500.433C822.814 500.433 822.401 500.433 822.607 500.433C822.814 500.433 822.401 500.433 822.607 500.433C830.871 500.433 821.987 508.29 821.987 516.353C821.987 524.21 815.377 530.826 807.113 530.826C806.907 530.826 806.7 530.826 806.494 530.826V531.86H838.721V530.826C838.514 530.826 838.308 530.826 838.101 530.826C829.838 530.62 823.227 524.21 823.227 516.353Z"
                            fill="var(--loungeChairStand)"
                        />
                        <path
                            id="Vector_136"
                            d="M863.923 477.483C858.759 474.175 849.463 478.31 844.918 480.998C840.579 483.479 836.654 483.686 835.208 477.07C833.762 470.247 839.34 458.048 843.265 451.845C846.984 445.436 850.289 436.959 847.603 433.237C844.711 429.309 838.1 431.996 822.813 435.098C806.7 438.406 797.817 434.477 794.511 432.41C791.826 430.756 789.966 427.448 788.727 427.241C788.314 427.034 788.107 427.241 787.694 428.482C786.454 432.203 784.802 464.457 789.347 485.34C793.891 506.222 808.766 518.628 822.607 519.248C841.819 520.282 856.9 510.151 863.51 496.298C866.816 489.475 867.849 479.964 863.923 477.483Z"
                            fill="var(--loungeChairTop)"
                        />
                        <path
                            id="Vector_137"
                            d="M859.792 500.64C853.801 505.809 844.298 507.049 835.828 500.847C827.358 494.644 822.194 484.306 813.724 478.517C805.46 472.728 800.709 477.069 799.263 478.724C797.817 480.378 792.033 487.821 790.38 472.934C788.934 459.702 789.967 437.579 789.967 434.271C789.967 429.722 788.727 424.553 787.694 428.275C786.661 431.996 784.802 464.25 789.347 485.133C793.892 506.015 808.766 518.421 822.607 519.041C842.232 520.075 857.52 509.53 863.924 495.264"
                            fill="var(--loungeChairBottom)"
                        />
                    </g>
                    <g id="Group_44">
                        <path
                            id="Vector_138"
                            d="M905.354 477.962V523.38C907.263 527.936 915.341 531.464 924.886 531.464C925.18 531.464 925.474 531.464 925.768 531.464V532.199H882.444V531.464C882.738 531.464 883.032 531.464 883.325 531.464C892.871 531.464 900.802 528.083 902.858 523.38V477.962"
                            fill="var(--loungeTableStand)"
                        />
                        <g id="Group_45">
                            <path
                                id="Vector_139"
                                d="M872.458 476.933H935.754V479.432H872.458V476.933Z"
                                fill="var(--loungeTableBase)"
                            />
                            <path
                                id="Vector_140"
                                d="M872.458 476.052H935.754V476.933H872.458V476.052Z"
                                fill="var(--loungeTableTop)"
                            />
                        </g>
                    </g>
                </g>
                <g id="Setting_3">
                    <g id="chair_5">
                        <path
                            id="Vector_141"
                            d="M597.409 469.985C597.409 459.963 608.478 449.676 598.2 449.676C597.937 449.676 598.464 449.676 598.2 449.676C597.937 449.676 598.464 449.676 598.2 449.676C587.659 449.676 598.991 459.699 598.991 469.985C598.991 480.008 607.424 488.448 617.965 488.448C618.229 488.448 618.492 488.448 618.756 488.448V489.767H577.645V488.448C577.908 488.448 578.172 488.448 578.435 488.448C588.976 488.184 597.409 480.008 597.409 469.985Z"
                            fill="var(--loungeChairStand)"
                        />
                        <path
                            id="Vector_142"
                            d="M545.493 420.4C552.081 416.18 563.941 421.455 569.738 424.883C575.272 428.048 580.28 428.312 582.124 419.872C583.969 411.168 576.854 395.607 571.846 387.694C567.103 379.518 562.886 368.704 566.312 363.956C570.002 358.945 578.435 362.374 597.936 366.33C618.492 370.55 629.824 365.539 634.04 362.901C637.466 360.791 639.838 356.571 641.419 356.307C641.946 356.044 642.21 356.307 642.737 357.89C644.318 362.637 646.426 403.783 640.629 430.422C634.831 457.061 615.856 472.887 598.2 473.678C573.691 474.997 554.453 462.073 546.02 444.401C541.804 435.697 540.486 423.565 545.493 420.4Z"
                            fill="var(--loungeChairTop)"
                        />
                        <path
                            id="Vector_143"
                            d="M550.763 449.94C558.405 456.534 570.528 458.116 581.333 450.204C592.138 442.291 598.726 429.103 609.531 421.718C620.072 414.333 626.133 419.872 627.978 421.982C629.823 424.092 637.202 433.587 639.31 414.597C641.155 397.717 639.837 369.495 639.837 365.275C639.837 359.472 641.418 352.879 642.736 357.626C644.053 362.374 646.425 403.519 640.628 430.158C634.83 456.798 615.855 472.623 598.199 473.414C573.163 474.733 553.662 461.281 545.492 443.082"
                            fill="var(--loungeChairBottom)"
                        />
                    </g>
                    <g id="chair_6">
                        <path
                            id="Vector_144"
                            d="M385.544 469.985C385.544 459.963 374.475 449.676 384.753 449.676C385.017 449.676 384.489 449.676 384.753 449.676C385.017 449.676 384.489 449.676 384.753 449.676C395.294 449.676 383.962 459.699 383.962 469.985C383.962 480.008 375.529 488.448 364.988 488.448C364.725 488.448 364.461 488.448 364.197 488.448V489.767H405.309V488.448C405.045 488.448 404.782 488.448 404.518 488.448C393.977 488.184 385.544 480.008 385.544 469.985Z"
                            fill="var(--loungeChairStand)"
                        />
                        <path
                            id="Vector_145"
                            d="M437.46 420.4C430.872 416.18 419.013 421.455 413.215 424.883C407.681 428.048 402.674 428.312 400.829 419.872C398.984 411.168 406.1 395.607 411.107 387.694C415.85 379.518 420.067 368.704 416.641 363.956C412.951 358.945 404.518 362.374 385.017 366.33C364.461 370.55 353.13 365.539 348.913 362.901C345.487 360.791 343.115 356.571 341.534 356.307C341.007 356.044 340.744 356.307 340.216 357.89C338.635 362.637 336.527 403.783 342.325 430.422C348.122 457.061 367.097 472.887 384.753 473.678C409.262 474.997 428.5 462.073 436.933 444.401C441.149 435.697 442.467 423.565 437.46 420.4Z"
                            fill="var(--loungeChairTop)"
                        />
                        <path
                            id="Vector_146"
                            d="M432.19 449.94C424.548 456.534 412.425 458.116 401.62 450.204C390.816 442.291 384.227 429.103 373.422 421.718C362.881 414.333 356.82 419.872 354.975 421.982C353.13 424.092 345.751 433.587 343.643 414.597C341.798 397.717 343.116 369.495 343.116 365.275C343.116 359.472 341.535 352.879 340.217 357.626C338.9 362.374 336.528 403.519 342.325 430.158C348.123 456.798 367.098 472.623 384.754 473.414C409.79 474.733 429.291 461.281 437.461 443.082"
                            fill="var(--loungeChairBottom)"
                        />
                    </g>
                    <g id="Group_46">
                        <path
                            id="Vector_147"
                            d="M490.313 421.011V478.948C492.748 484.761 503.052 489.261 515.229 489.261C515.604 489.261 515.979 489.261 516.354 489.261V490.198H461.087V489.261C461.462 489.261 461.837 489.261 462.211 489.261C474.389 489.261 484.505 484.948 487.128 478.948V421.011"
                            fill="var(--loungeTableStand)"
                        />
                        <g id="Group_47">
                            <path
                                id="Vector_148"
                                d="M448.347 419.698H529.092V422.886H448.347V419.698Z"
                                fill="var(--loungeTableBase)"
                            />
                            <path
                                id="Vector_149"
                                d="M448.347 418.573H529.092V419.698H448.347V418.573Z"
                                fill="var(--loungeTableTop)"
                            />
                        </g>
                    </g>
                </g>
                <g id="Setting_4">
                    <g id="chair_7">
                        <path
                            id="Vector_150"
                            d="M212.743 476.455C212.743 469.641 220.268 462.647 213.28 462.647C213.101 462.647 213.46 462.647 213.28 462.647C213.101 462.647 213.46 462.647 213.28 462.647C206.113 462.647 213.818 469.462 213.818 476.455C213.818 483.27 219.552 489.008 226.719 489.008C226.898 489.008 227.077 489.008 227.256 489.008V489.905H199.305V489.008C199.484 489.008 199.663 489.008 199.842 489.008C207.009 488.829 212.743 483.27 212.743 476.455Z"
                            fill="var(--loungeChairStand)"
                        />
                        <path
                            id="Vector_151"
                            d="M177.446 442.742C181.925 439.872 189.988 443.459 193.93 445.79C197.693 447.942 201.097 448.122 202.351 442.383C203.606 436.465 198.768 425.885 195.363 420.505C192.138 414.946 189.271 407.593 191.601 404.366C194.109 400.958 199.843 403.29 213.102 405.98C227.078 408.849 234.782 405.442 237.649 403.648C239.979 402.214 241.591 399.344 242.666 399.165C243.024 398.986 243.204 399.165 243.562 400.241C244.637 403.469 246.071 431.444 242.129 449.556C238.187 467.668 225.286 478.428 213.281 478.966C196.618 479.863 183.538 471.075 177.804 459.061C174.937 453.143 174.041 444.894 177.446 442.742Z"
                            fill="var(--loungeChairTop)"
                        />
                        <path
                            id="Vector_152"
                            d="M181.029 462.827C186.225 467.31 194.467 468.386 201.813 463.006C209.16 457.626 213.639 448.66 220.985 443.639C228.153 438.617 232.274 442.383 233.528 443.818C234.782 445.252 239.799 451.708 241.233 438.797C242.487 427.32 241.591 408.132 241.591 405.262C241.591 401.317 242.666 396.834 243.562 400.062C244.458 403.29 246.07 431.265 242.128 449.377C238.187 467.489 225.286 478.249 213.281 478.787C196.259 479.683 183 470.538 177.445 458.164"
                            fill="var(--loungeChairBottom)"
                        />
                    </g>
                    <g id="chair_8">
                        <path
                            id="Vector_153"
                            d="M68.6965 476.455C68.6965 469.641 61.1711 462.647 68.159 462.647C68.3382 462.647 67.9798 462.647 68.159 462.647C68.3382 462.647 67.9798 462.647 68.159 462.647C75.3261 462.647 67.6215 469.462 67.6215 476.455C67.6215 483.27 61.8878 489.008 54.7208 489.008C54.5416 489.008 54.3624 489.008 54.1832 489.008V489.905H82.1348V489.008C81.9556 489.008 81.7764 489.008 81.5973 489.008C74.4302 488.829 68.6965 483.27 68.6965 476.455Z"
                            fill="var(--loungeChairStand)"
                        />
                        <path
                            id="Vector_154"
                            d="M103.994 442.742C99.5143 439.872 91.4513 443.459 87.5094 445.79C83.7467 447.942 80.3423 448.122 79.0881 442.383C77.8338 436.465 82.6716 425.885 86.076 420.505C89.3012 414.946 92.168 407.593 89.8387 404.366C87.3302 400.958 81.5966 403.29 68.3374 405.98C54.3616 408.849 46.657 405.442 43.7901 403.648C41.4608 402.214 39.8483 399.344 38.7732 399.165C38.4148 398.986 38.2357 399.165 37.8773 400.241C36.8023 403.469 35.3688 431.444 39.3107 449.556C43.2526 467.668 56.1534 478.428 68.1583 478.966C84.8217 479.863 97.9017 471.075 103.635 459.061C106.502 453.143 107.398 444.894 103.994 442.742Z"
                            fill="var(--loungeChairTop)"
                        />
                        <path
                            id="Vector_155"
                            d="M100.411 462.827C95.2144 467.31 86.9723 468.386 79.626 463.006C72.2797 457.626 67.8002 448.66 60.454 443.639C53.2869 438.617 49.1658 442.383 47.9115 443.818C46.6573 445.252 41.6403 451.708 40.2069 438.797C38.9527 427.32 39.8485 408.132 39.8485 405.262C39.8485 401.317 38.7735 396.834 37.8776 400.062C36.9817 403.29 35.3691 431.265 39.311 449.377C43.2529 467.489 56.1537 478.249 68.1586 478.787C85.1805 479.683 98.4396 470.538 103.994 458.164"
                            fill="var(--loungeChairBottom)"
                        />
                    </g>
                    <g id="Group_48">
                        <path
                            id="Vector_156"
                            d="M139.93 443.158V482.55C141.585 486.502 148.591 489.561 156.871 489.561C157.125 489.561 157.38 489.561 157.635 489.561V490.199H120.059V489.561C120.314 489.561 120.569 489.561 120.823 489.561C129.103 489.561 135.981 486.629 137.764 482.55V443.158"
                            fill="var(--loungeTableStand)"
                        />
                        <g id="Group_49">
                            <path
                                id="Vector_157"
                                d="M111.396 442.265H166.295V444.432H111.396V442.265Z"
                                fill="var(--loungeTableBase)"
                            />
                            <path
                                id="Vector_158"
                                d="M111.396 441.5H166.295V442.265H111.396V441.5Z"
                                fill="var(--loungeTableTop)"
                            />
                        </g>
                    </g>
                </g>
            </g>
            <rect
                id="Rectangle 62"
                width="1438"
                height="22"
                fill="var(--loungeWall)"
            />
            <g
                v-if="getTable('1')"
                :id="`NETW-${networkingId}-1`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6"
                    cx="86.9055"
                    cy="394.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="A"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="81.6753" y="399.608">A</tspan>
                </text>
                <g id="NETW0101">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7"
                        cx="102"
                        cy="384"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="0"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-01"
                            x="99.0996"
                            y="387.87"
                        >
                            {{ getNumAttendees("NETW-01-01") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('9')"
                :id="`NETW-${networkingId}-9`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_2"
                    cx="75.9055"
                    cy="237.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="I"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="73.7734" y="242.608">I</tspan>
                </text>
                <g id="NETW0109">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_2"
                        cx="89"
                        cy="226"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="8"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-09"
                            x="86.0996"
                            y="229.87"
                        >
                            {{ getNumAttendees("NETW-01-09") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('15')"
                :id="`NETW-${networkingId}-15`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_3"
                    cx="1079.91"
                    cy="313.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="O"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="1073.94" y="318.608">O</tspan>
                </text>
                <g id="NETW0115">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_3"
                        cx="1095"
                        cy="303"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="10"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-15"
                            x="1091.5"
                            y="306.87"
                        >
                            {{ getNumAttendees("NETW-01-15") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('16')"
                :id="`NETW-${networkingId}-16`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_4"
                    cx="318.906"
                    cy="158.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="P"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="314.569" y="163.608">P</tspan>
                </text>
                <g id="NETW0116">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_4"
                        cx="332"
                        cy="146"
                        r="7.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="7"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-16"
                            x="329.1"
                            y="149.87"
                        >
                            {{ getNumAttendees("NETW-01-16") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('17')"
                :id="`NETW-${networkingId}-17`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_5"
                    cx="623.906"
                    cy="100.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="Q"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="617.943" y="105.608">Q</tspan>
                </text>
                <g id="NETW0117">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_5"
                        cx="639"
                        cy="90"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="7_2"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-17"
                            x="636.1"
                            y="93.87"
                        >
                            {{ getNumAttendees("NETW-01-17") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('18')"
                :id="`NETW-${networkingId}-18`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_6"
                    cx="884.906"
                    cy="109.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="R"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="880.085" y="114.608">R</tspan>
                </text>
                <g id="NETW0118">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_6"
                        cx="900"
                        cy="99"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="7_3"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-18"
                            x="897.1"
                            y="102.87"
                        >
                            {{ getNumAttendees("NETW-01-18") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('19')"
                :id="`NETW-${networkingId}-19`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_7"
                    cx="1052.91"
                    cy="196.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="S"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="1048.98" y="201.608">S</tspan>
                </text>
                <g id="NETW0119">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_7"
                        cx="1068"
                        cy="186"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="7_4"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-19"
                            x="1065.1"
                            y="189.87"
                        >
                            {{ getNumAttendees("NETW-01-19") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('20')"
                :id="`NETW-${networkingId}-20`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_8"
                    cx="1160.91"
                    cy="268.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="T"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="1156.54" y="273.608">T</tspan>
                </text>
                <g id="NETW0120">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_8"
                        cx="1176"
                        cy="258"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="7_5"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-20"
                            x="1173.1"
                            y="261.87"
                        >
                            {{ getNumAttendees("NETW-01-20") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('21')"
                :id="`NETW-${networkingId}-21`"
                v-attendeecount
            >
                <circle
                    id="Ellipse 6_8"
                    cx="1289.91"
                    cy="278.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="U"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="1284.54" y="283.608">U</tspan>
                </text>
                <g id="NETW0121">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_8"
                        cx="1305"
                        cy="268"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="7_5"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-21"
                            x="1302.1"
                            y="271.87"
                        >
                            {{ getNumAttendees("NETW-01-21") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('22')"
                :id="`NETW-${networkingId}-22`"
                v-attendeecount
            >
                <circle
                    id="Ellipse 6_9"
                    cx="161.906"
                    cy="72.9016"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="V"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="156.675" y="77.6082">V</tspan>
                </text>
                <g id="NETW0122">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_9"
                        cx="177"
                        cy="62"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="7_6"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-22"
                            x="174.1"
                            y="65.87"
                        >
                            {{ getNumAttendees("NETW-01-22") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('23')"
                :id="`NETW-${networkingId}-23`"
                v-attendeecount
            >
                <circle
                    id="Ellipse 6_10"
                    cx="438.906"
                    cy="72.9016"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="W"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="431.148" y="77.6082">W</tspan>
                </text>
                <g id="NETW0123">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_10"
                        cx="454"
                        cy="62"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="7_7"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-23"
                            x="451.1"
                            y="65.87"
                        >
                            {{ getNumAttendees("NETW-01-23") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('24')"
                :id="`NETW-${networkingId}-24`"
                v-attendeecount
            >
                <circle
                    id="Ellipse 6_11"
                    cx="715.906"
                    cy="72.9016"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="X"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="710.91" y="77.6082">X</tspan>
                </text>
                <g id="NETW0124">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_11"
                        cx="731"
                        cy="62"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="7_8"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-24"
                            x="728.1"
                            y="65.87"
                        >
                            {{ getNumAttendees("NETW-01-24") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('25')"
                :id="`NETW-${networkingId}-25`"
                v-attendeecount
            >
                <circle
                    id="Ellipse 6_12"
                    cx="992.906"
                    cy="72.9016"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="Y"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="988.093" y="77.6082">Y</tspan>
                </text>
                <g id="NETW0125">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_12"
                        cx="1008"
                        cy="62"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="7_9"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-25"
                            x="1005.1"
                            y="65.87"
                        >
                            {{ getNumAttendees("NETW-01-25") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('26')"
                :id="`NETW-${networkingId}-26`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_13"
                    cx="1269.91"
                    cy="72.9016"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="Z"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="1265.37" y="77.6082">Z</tspan>
                </text>
                <g id="NETW0126">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_13"
                        cx="1285"
                        cy="62"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="7_10"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-26"
                            x="1282.1"
                            y="65.87"
                        >
                            {{ getNumAttendees("NETW-01-26") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('10')"
                :id="`NETW-${networkingId}-10`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_9"
                    cx="259.906"
                    cy="310.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="J"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="256.777" y="315.608">J</tspan>
                </text>
                <g id="NETW0110">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_9"
                        cx="275"
                        cy="300"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="9"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-10"
                            x="272.1"
                            y="303.87"
                        >
                            {{ getNumAttendees("NETW-01-10") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('5')"
                :id="`NETW-${networkingId}-5`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_10"
                    cx="844.906"
                    cy="426.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="E"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="840.708" y="431.608">E</tspan>
                </text>
                <g id="NETW0105">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_10"
                        cx="860"
                        cy="416"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="4"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-05"
                            x="857.1"
                            y="419.87"
                        >
                            {{ getNumAttendees("NETW-01-05") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('7')"
                :id="`NETW-${networkingId}-7`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_11"
                    cx="1182.91"
                    cy="351.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="G"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="1177.56" y="356.608">G</tspan>
                </text>
                <g id="NETW0107">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_11"
                        cx="1198"
                        cy="341"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="6"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-07"
                            x="1195.1"
                            y="344.87"
                        >
                            {{ getNumAttendees("NETW-01-07") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('3')"
                :id="`NETW-${networkingId}-3`"
                v-attendeecount
            >
                <g id="LegendC">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 6_12"
                        cx="419.906"
                        cy="353.902"
                        r="13.9016"
                        fill="var(--loungeButton)"
                        stroke="white"
                        stroke-width="2"
                    />
                    <text
                        id="C"
                        fill="var(--loungeAttendeeCount)"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="15"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan x="414.968" y="358.608">C</tspan>
                    </text>
                </g>
                <g id="NETW0103">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_12"
                        cx="435"
                        cy="343"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="2"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-03"
                            x="432.1"
                            y="346.87"
                        >
                            {{ getNumAttendees("NETW-01-03") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('4')"
                :id="`NETW-${networkingId}-4`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_13"
                    cx="571.906"
                    cy="353.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="D"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="566.397" y="358.608">D</tspan>
                </text>
                <g id="NETW0104">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_13"
                        cx="587"
                        cy="343"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="3"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-04"
                            x="584.1"
                            y="346.87"
                        >
                            {{ getNumAttendees("NETW-01-04") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('11')"
                :id="`NETW-${networkingId}-11`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_14"
                    cx="404.906"
                    cy="266.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="K"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="399.858" y="271.608">K</tspan>
                </text>
                <g id="NETW0111">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_14"
                        cx="420"
                        cy="256"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="3_2"
                        fill="var(--loungeAttendeeCount)"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-11"
                            x="417.1"
                            y="259.87"
                        >
                            {{ getNumAttendees("NETW-01-11") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('12')"
                :id="`NETW-${networkingId}-12`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_15"
                    cx="497.906"
                    cy="220.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="L"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="494.104" y="225.608">L</tspan>
                </text>
                <g id="NETW0112">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_15"
                        cx="513"
                        cy="210"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="3_3"
                        fill="var(--loungeAttendeeCount)"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-12"
                            x="510.1"
                            y="213.87"
                        >
                            {{ getNumAttendees("NETW-01-12") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('13')"
                :id="`NETW-${networkingId}-13`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_16"
                    cx="738.906"
                    cy="300.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="M"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="731.91" y="305.608">M</tspan>
                </text>
                <g id="NETW0113">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_16"
                        cx="754"
                        cy="290"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="3_4"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-13"
                            x="751.1"
                            y="293.87"
                        >
                            {{ getNumAttendees("NETW-01-13") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('14')"
                :id="`NETW-${networkingId}-14`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_17"
                    cx="904.906"
                    cy="311.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="N"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="899.331" y="316.805">N</tspan>
                </text>
                <g id="NETW0114">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_17"
                        cx="920"
                        cy="301"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="3_5"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-14"
                            x="917.1"
                            y="304.87"
                        >
                            {{ getNumAttendees("NETW-01-14") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('2')"
                :id="`NETW-${networkingId}-2`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_18"
                    cx="199.906"
                    cy="394.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="B"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="195.115" y="399.608">B</tspan>
                </text>
                <g id="NETW0102">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_18"
                        cx="215"
                        cy="384"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="1"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-02"
                            x="212.1"
                            y="387.87"
                        >
                            {{ getNumAttendees("NETW-01-02") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('6')"
                :id="`NETW-${networkingId}-6`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_19"
                    cx="957.906"
                    cy="426.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="F"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="953.752" y="431.608">F</tspan>
                </text>
                <g id="NETW0106">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_19"
                        cx="973"
                        cy="416"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="5"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-06"
                            x="970.1"
                            y="419.87"
                        >
                            {{ getNumAttendees("NETW-01-06") }}
                        </tspan>
                    </text>
                </g>
            </g>
            <g
                v-if="getTable('8')"
                :id="`NETW-${networkingId}-8`"
                v-attendeecount
            >
                <circle
                    class="mg-shadow"
                    id="Ellipse 6_20"
                    cx="1295.91"
                    cy="351.902"
                    r="13.9016"
                    fill="var(--loungeButton)"
                    stroke="white"
                    stroke-width="2"
                />
                <text
                    id="H"
                    fill="var(--loungeAttendeeCount)"
                    xml:space="preserve"
                    style="white-space: pre"
                    font-size="15"
                    font-weight="bold"
                    letter-spacing="0em"
                >
                    <tspan x="1290.33" y="356.608">H</tspan>
                </text>
                <g id="NETW0108">
                    <circle
                        class="mg-shadow"
                        id="Ellipse 7_20"
                        cx="1311"
                        cy="341"
                        r="9.5"
                        fill="var(--loungeBgNumber)"
                        stroke="white"
                    />
                    <text
                        id="7_6"
                        fill="white"
                        xml:space="preserve"
                        style="white-space: pre"
                        font-size="10"
                        font-weight="bold"
                        letter-spacing="0em"
                    >
                        <tspan
                            class="num-attendees"
                            data-session-id="NETW-01-08"
                            x="1308.1"
                            y="344.87"
                        >
                            {{ getNumAttendees("NETW-01-08") }}
                        </tspan>
                    </text>
                </g>
            </g>
        </g>
        <defs>
            <filter
                id="filter0_d"
                x="90"
                y="374"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter1_d"
                x="77"
                y="216"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter2_d"
                x="1083"
                y="293"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter3_d"
                x="322"
                y="138"
                width="24"
                height="24"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter4_d"
                x="627"
                y="80"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter5_d"
                x="888"
                y="89"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter6_d"
                x="1056"
                y="176"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter7_d"
                x="1164"
                y="248"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter8_d"
                x="263"
                y="290"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter9_d"
                x="828.004"
                y="406"
                width="47.9961"
                height="43.8033"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter10_d"
                x="1166"
                y="331"
                width="47.9961"
                height="43.8033"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter11_d"
                x="403.004"
                y="333"
                width="47.9961"
                height="43.8033"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter12_d"
                x="575"
                y="333"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter13_d"
                x="408"
                y="246"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter14_d"
                x="501"
                y="200"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter15_d"
                x="742"
                y="280"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter16_d"
                x="908"
                y="291"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter17_d"
                x="183.004"
                y="374"
                width="47.9961"
                height="43.8033"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter18_d"
                x="941.004"
                y="406"
                width="47.9961"
                height="43.8033"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <filter
                id="filter19_d"
                x="1279"
                y="331"
                width="47.9961"
                height="43.8033"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                />
            </filter>
            <clipPath id="clip0">
                <rect width="1438" height="532" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "NetworkingRoomLounge",
    props: {
        networkingId: {
            type: String,
            required: true,
            default: ""
        },
        tables: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    directives: {
        attendeecount: {
            inserted: function(el, binding, vnode) {
                vnode.context.addAttendeeCount(el);
            },
            update: function(el, binding, vnode) {
                vnode.context.addAttendeeCount(el);
            }
        }
    },
    methods: {
        getTable(sessionId) {
            let toFind = `NETW-${this.networkingId}-${sessionId}`;

            if (sessionId.includes("NETW")) {
                toFind = sessionId;
            }

            const returnValue = this.tables.find(
                (item) => toFind === item.sessionId
            );

            return returnValue;
        },
        getTableProperty(sessionId, property) {
            let returnValue = "";
            const table = this.getTable(sessionId);

            if (table) {
                returnValue = table[property];
            }

            return returnValue;
        },
        getNumAttendees(sessionId) {
            return this.getTableProperty(sessionId, "numAttendees") || "0";
        },
        addAttendeeCount(element) {
            const id = element.getAttribute("id");
            const count = `${this.getNumAttendees(id)}`;
            const target = element.querySelector(".num-attendees");

            if (target) {
                target.innerHTML = count.trim();
            }
        }
    }
};
</script>
<style scoped>
.mg-shadow {
    filter: drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.1));
}
</style>
