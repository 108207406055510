import {
    Module,
    VuexModule,
    Mutation,
    Action,
    config
} from "vuex-module-decorators";
import { getApiClient } from "@/services/api";
import store from "../index";
import { MeetingTable } from "./../../types/interfaces";

// This module is only for functionality related to the following endpoint:
const endpoint = "networkingTables";

// Set rawError to true by default on all @Action decorators
config.rawError = true;

@Module({
    dynamic: true,
    store: store,
    name: "NetworkingModule",
    namespaced: true
})
export default class NetworkingModule extends VuexModule {
    activeTables: Array<MeetingTable> = [];

    @Mutation
    public setNetworkingTables(data: Array<MeetingTable>) {
        this.activeTables = data;
    }

    @Action({ commit: "setNetworkingTables" })
    getTables(networkingRoomId: string) {
        const Id = networkingRoomId;
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}/${Id}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
}
